<template>
    <div class="ces-main" style="height: 100%">
        <div class="boxbutton">
            <el-row>
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <!--<el-button type="primary" plain size="mini" v-if="deliveryBillSearchFlag" @click="deliverFileSearch">查询</el-button>-->
                        <el-button type="primary" plain size="mini" v-if="deliverFileModifyFlag" @click="deliverFileModify">修改</el-button>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-input maxlength="50" placeholder="请输入司机姓名" v-model="AccurateAndUnique_name" size="mini" style="width: 250px" class="input-with-input">
                            <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
                        </el-input>
                        <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog = true">高级查询</el-button>
                    </div>
                </el-col>
                <el-drawer
                        title="高级查询条件"
                        :before-close="cancelForm"
                        :visible.sync="dialog"
                        direction="rtl"
                        custom-class="demo-drawer"
                        ref="drawer"
                >
                    <div class="demo-drawer__content">
                        <el-row>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-card class="box-card">
                                        <div>
                                            <el-form label-width="140px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                                                <el-form-item label="用户id" prop="userId">
                                                    <el-input  maxlength="18" v-model="dataForms.userId"></el-input>
                                                </el-form-item>
                                                <el-form-item label="账号名称" prop="username">
                                                    <el-input  maxlength="18" v-model="dataForms.username" placeholder="支持模糊搜索"></el-input>
                                                </el-form-item>
                                                <el-form-item label="审核状态" prop="verify">
                                                    <el-select v-model="dataForms.verify" placeholder="请选择审核状态" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="未审核" value="01"></el-option>
                                                        <el-option label="审核通过" value="02"></el-option>
                                                        <el-option label="审核失败" value="03"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="状态" prop="status">
                                                    <el-select v-model="dataForms.status" placeholder="请选择司机状态" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="启用" value="1"></el-option>
                                                        <el-option label="冻结" value="0"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="驾驶证编号" prop="driverLicense">
                                                    <el-input   v-model="dataForms.driverLicense"></el-input>
                                                </el-form-item>
                                                <el-form-item label="行驶证编号" prop="vehicleLicense">
                                                    <el-input   v-model="dataForms.vehicleLicense"></el-input>
                                                </el-form-item>
                                                <el-form-item label="身份证号码" prop="identityCard">
                                                    <el-input   v-model="dataForms.identityCard"></el-input>
                                                </el-form-item>
                                                <el-form-item label="合同编号" prop="contractNo">
                                                    <el-input   v-model="dataForms.contractNo"></el-input>
                                                </el-form-item>
                                            </el-form>
                                            <div slot="footer" class="dialog-footer" style="text-align: center">
                                                <!--<el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ? '提交中 ...' : '确 定' }}</el-button>-->
                                                <el-button size="mini" v-if="deliveryBillSearchFlag" type="primary" @click="handleClose" :loading="loading">{{ loading ? '提交中 ...' : '查 询' }}</el-button>
                                                <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </el-drawer>
            </el-row>
        </div>
        <div class="tableMain">
            <ces-table
                    size='mini'
                    :isSelection='true'
                    :isIndex='true'
                    :isPagination='true'
                    :isHandle='true'
                    v-loading='loading'
                    :tableData='tableData'
                    :tableCols='tableCols'
                    :pagination='pagination'
                    @refresh="handleCurrentChange"
                    @handleImgClick="handleImgClick"
                    ref='cesTable'
            >
            </ces-table>
        </div>
        <el-dialog :visible.sync="dialogVisibleImgUrl" :before-close="handleCloseImgDialog">
            <!--<img width="100%" :src="dialogImageUrl_Cli" alt="">-->
            <el-image
                    :src="dialogImageUrl_Cli"
                    :z-index="3000"
                    :preview-src-list="dialogImageUrl_Cli_srcList">
            </el-image>
        </el-dialog>
        <el-dialog
                title="司机文件信息修改"
                :visible.sync="dialog_driverFile"
                width="85%"
                :modal="true"
                center
                :before-close="handleClose_driverFile">
            <el-card class="box-card" :body-style="{ padding: '10px' }">
                <div slot="header">
                    <span style="font-size: 14px">公司信息</span>
                </div>
                <div>
                    <el-form :model="driverFileForm"  ref="driverFileForm" :rules="driverFileFormrules" label-width="110px" class="demo-ruleForm" size="mini" label-position="center">
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="用户ID" prop="userId">
                                    <el-input v-model="driverFileForm.userId" :disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="账号名称" prop="username">
                                    <el-input v-model="driverFileForm.username" :disabled="true"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="司机姓名" prop="name">
                                    <el-input v-model="driverFileForm.name"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="审核状态" prop="verify">
                                    <el-select v-model="driverFileForm.verify" placeholder="请选择状态">
                                        <el-option label="未审核" value="01"></el-option>
                                        <el-option label="审核通过" value="02"></el-option>
                                        <el-option label="审核失败" value="03"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <!--<el-col :span="6">-->
                                <!--<el-form-item label="状态" prop="status">-->
                                    <!--<el-select v-model="driverFileForm.status" placeholder="请选择司机状态">-->
                                        <!--<el-option label="启用" value="1"></el-option>-->
                                        <!--<el-option label="冻结" value="0"></el-option>-->
                                    <!--</el-select>-->
                                <!--</el-form-item>-->
                            <!--</el-col>-->
                            <el-col :span="6">
                                <el-form-item label="驾驶证编号" prop="driverLicense">
                                    <el-input v-model="driverFileForm.driverLicense"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="行驶证编号" prop="vehicleLicense">
                                    <el-input v-model="driverFileForm.vehicleLicense"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="身份证号码" prop="identityCard">
                                    <el-input v-model="driverFileForm.identityCard"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="合同编号" prop="contractNo">
                                    <el-input v-model="driverFileForm.contractNo"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="备注" prop="remark">
                                    <el-input v-model="driverFileForm.remark"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="司机驾驶证附件">
                                    <el-image
                                            style="width: 100px; height: 100px"
                                            :src="imgurl_s"
                                            :z-index="3000"
                                            v-if="imgflagmodel"
                                            :preview-src-list="srcList">
                                    </el-image>
                                    <el-link v-if="imgflagdel" icon="el-icon-delete"  @click="delimg">删除</el-link>
                                    <el-upload
                                            class="upload"
                                            v-if="imgflag"
                                            :class="{ hide: hideUp,uoloadSty:showBtnImg,disUoloadSty:noneBtnImg}"
                                            :action="getUploadUrl"
                                            list-type="picture-card"
                                            :limit="1"
                                            accept="image/*"
                                            name="image"
                                            :on-success="handleUpSuccess"
                                            :on-error="handleUpError"
                                            :on-change="handleUpUpload"
                                            :on-preview="handleUpPreview"
                                            :on-remove="handleUpRemove">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible">
                                        <img width="100%" :src="dialogImageUrl" alt="">
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="司机行驶证附件">
                                    <el-image
                                            style="width: 100px; height: 100px"
                                            :src="imgurl_s_vehicleLicenseUrl"
                                            :z-index="3000"
                                            v-if="imgflagmodel1"
                                            :preview-src-list="srcList1">
                                    </el-image>
                                    <el-link v-if="imgflagdel1" icon="el-icon-delete"  @click="delimg1">删除</el-link>
                                    <el-upload
                                            class="upload"
                                            v-if="imgflag1"
                                            :class="{ hide: hideUp1,uoloadSty:showBtnImg1,disUoloadSty:noneBtnImg1}"
                                            :action="getUploadUrl"
                                            list-type="picture-card"
                                            :limit="1"
                                            accept="image/*"
                                            name="image"
                                            :on-success="handleUpSuccess1"
                                            :on-error="handleUpError1"
                                            :on-change="handleUpUpload1"
                                            :on-preview="handleUpPreview1"
                                            :on-remove="handleUpRemove1">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible1">
                                        <img width="100%" :src="dialogImageUrl1" alt="">
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="司机身份证正面附件">
                                    <el-image
                                            style="width: 100px; height: 100px"
                                            :src="imgurl_s_identityPositiveUrl"
                                            :z-index="3000"
                                            v-if="imgflagmodel2"
                                            :preview-src-list="srcList2">
                                    </el-image>
                                    <el-link v-if="imgflagdel2" icon="el-icon-delete"  @click="delimg2">删除</el-link>
                                    <el-upload
                                            class="upload"
                                            v-if="imgflag2"
                                            :class="{ hide: hideUp2,uoloadSty:showBtnImg2,disUoloadSty:noneBtnImg2}"
                                            :action="getUploadUrl"
                                            list-type="picture-card"
                                            :limit="1"
                                            accept="image/*"
                                            name="image"
                                            :on-success="handleUpSuccess2"
                                            :on-error="handleUpError2"
                                            :on-change="handleUpUpload2"
                                            :on-preview="handleUpPreview2"
                                            :on-remove="handleUpRemove2">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible2">
                                        <img width="100%" :src="dialogImageUrl2" alt="">
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-form-item label="司机身份证反面附件">
                                    <el-image
                                            style="width: 100px; height: 100px"
                                            :src="imgurl_s_identityBackUrl"
                                            :z-index="3000"
                                            v-if="imgflagmodel3"
                                            :preview-src-list="srcList3">
                                    </el-image>
                                    <el-link v-if="imgflagdel3" icon="el-icon-delete"  @click="delimg3">删除</el-link>
                                    <el-upload
                                            class="upload"
                                            v-if="imgflag3"
                                            :class="{ hide: hideUp3,uoloadSty:showBtnImg3,disUoloadSty:noneBtnImg3}"
                                            :action="getUploadUrl"
                                            list-type="picture-card"
                                            :limit="1"
                                            accept="image/*"
                                            name="image"
                                            :on-success="handleUpSuccess3"
                                            :on-error="handleUpError3"
                                            :on-change="handleUpUpload3"
                                            :on-preview="handleUpPreview3"
                                            :on-remove="handleUpRemove3">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible3">
                                        <img width="100%" :src="dialogImageUrl3" alt="">
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6">
                                <el-form-item label="司机合作合同附件">
                                    <el-image
                                            style="width: 100px; height: 100px"
                                            :src="imgurl_s_DcontractUrl"
                                            :z-index="3000"
                                            v-if="imgflagmodel4"
                                            :preview-src-list="srcList4">
                                    </el-image>
                                    <el-link v-if="imgflagdel4" icon="el-icon-delete"  @click="delimg4">删除</el-link>
                                    <el-upload
                                            class="upload"
                                            v-if="imgflag4"
                                            :class="{ hide: hideUp4,uoloadSty:showBtnImg4,disUoloadSty:noneBtnImg4}"
                                            :action="getUploadUrl"
                                            list-type="picture-card"
                                            :limit="1"
                                            accept="image/*"
                                            name="image"
                                            :on-success="handleUpSuccess4"
                                            :on-error="handleUpError4"
                                            :on-change="handleUpUpload4"
                                            :on-preview="handleUpPreview4"
                                            :on-remove="handleUpRemove4">
                                        <i class="el-icon-plus"></i>
                                    </el-upload>
                                    <el-dialog :visible.sync="dialogVisible4">
                                        <img width="100%" :src="dialogImageUrl4" alt="">
                                    </el-dialog>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </el-card>
            <span slot="footer" class="dialog-footer" style="text-align: center">
                <el-button type="primary" @click="save_driverFile">提 交</el-button>
                <el-button @click="handleClose_driverFile">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    //引入组件
    import SearchForm from '../../components/common/form.vue'
    import cesTable from '../../components/common/table.vue'
    import Drawer from '../../components/common/drawer.vue'
    import arrayQuery from '../../components/arrayQuery.js'
    import axios from 'axios'
    import qs from 'qs'
    import 'element-ui/lib/theme-chalk/index.css'
    export default {
        data(){
            return {
                table: false,
                dialog: false,
                loading: false,
                dialogVisibleImgUrl: false,
                dialogImageUrl_Cli:'',
                dialog_driverFile:false,
                hideUp:false,
                hideUp1:false,
                hideUp2:false,
                hideUp3:false,
                hideUp4:false,
                hideBack:false,
                hideBusiness:false,
                imagesUp:[],
                imagesBack:[],
                imagesBusiness:[],
                fileList:[],
                dialogImageUrl:'',
                dialogImageUrl1:'',
                dialogImageUrl2:'',
                dialogImageUrl3:'',
                dialogImageUrl4:'',
                dialogVisible: false,
                dialogVisible1: false,
                dialogVisible2: false,
                dialogVisible3: false,
                dialogVisible4: false,
                showBtnImg:true,
                showBtnImg1:true,
                showBtnImg2:true,
                showBtnImg3:true,
                showBtnImg4:true,
                noneBtnImg:false,
                noneBtnImg1:false,
                noneBtnImg2:false,
                noneBtnImg3:false,
                noneBtnImg4:false,
                limitCountImg:1,
                limitCountImg1:1,
                limitCountImg2:1,
                limitCountImg3:1,
                limitCountImg4:1,
                imgflag:false,
                imgflag1:false,
                imgflag2:false,
                imgflag3:false,
                imgflag4:false,
                imgurl_s:'',
                imgurl_s_vehicleLicenseUrl:'',
                imgurl_s_identityPositiveUrl:'',
                imgurl_s_identityBackUrl:'',
                imgurl_s_DcontractUrl:'',
                imgflagdel:true,
                imgflagdel1:true,
                imgflagdel2:true,
                imgflagdel3:true,
                imgflagdel4:true,
                imgflagmodel:true,
                imgflagmodel1:true,
                imgflagmodel2:true,
                imgflagmodel3:true,
                imgflagmodel4:true,
                srcList: [],
                srcList1: [],
                srcList2: [],
                srcList3: [],
                srcList4: [],
                dialogImageUrl_Cli_srcList:[],
                AccurateAndUnique_name:'',
                dataForms:{
                    userId:'',
                    username:'',
                    name:'',
                    verify:'',
                    status:'',
                    driverLicense:'',
                    vehicleLicense:'',
                    identityCard:'',
                    contractNo:''
                },
                driverFileForm:{
                    userId: '',
                    username:'',
                    name:'',
                    verify:'',
                    status:'',
                    driverLicense:'',
                    vehicleLicense:'',
                    identityCard: '',
                    contractNo:'',
                    driverLicenseUrl:'',
                    vehicleLicenseUrl:'',
                    identityPositiveUrl:'',
                    identityBackUrl: '',
                    contractUrl:'',
                    remark:''
                },
                driverFileFormrules:{

                },
                formLabelWidth: '80px',
                timer: null,
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                tableCols:[ //表格列数据
                    {label:'用户id',prop:'userId',width:'160',align:'center'},
                    {label:'账号名称',prop:'username',width:'160',align:'center'},
                    {label:'用户姓名',prop:'name',width:'160',align:'center'},
                    {label:'审核状态',prop:'verify',align:'center',
                        formatter: (row) => {
                            if (row.verify == '01') {
                                return "未审核";
                            } else if (row.verify == '02') {
                                return "审核通过";
                            } else if (row.verify == '03') {
                                return "审核失败";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'状态',prop:'status',align:'center',
                        formatter: (row) => {
                            if (row.status == true) {
                                return "启用 ";
                            } else if (row.status == false) {
                                return "冻结";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'司机驾驶证编号',prop:'driverLicense',align:'center',width:'160'},
                    {label:'行驶证编号',prop:'vehicleLicense',align:'center',width:'160'},
                    {label:'身份证号码',prop:'identityCard',align:'center',width:'160'},
                    {label:'合同编号',prop:'contractNo',align:'center',width:'160'},
                    {label:'驾驶证附件',type: "imgLink",prop:'driverLicenseUrl',align:'center',width:'160',
                        formatter: (row) => {
                            if (row.driverLicenseUrl == '' || row.driverLicenseUrl == null || row.driverLicenseUrl == 'null') {
                                return "";
                            } else {
                                return "查看附件";
                            }
                        }
                    },
                    {label:'行驶证附件',type: "imgLink",prop:'vehicleLicenseUrl',align:'center',width:'160',
                        formatter: (row) => {
                            if (row.vehicleLicenseUrl == '' || row.vehicleLicenseUrl == null || row.vehicleLicenseUrl == 'null') {
                                return "";
                            } else {
                                return "查看附件";
                            }
                        }
                    },
                    {label:'司机身份证正面附件',type: "imgLink",prop:'identityPositiveUrl',align:'center',width:'160',
                        formatter: (row) => {
                            if (row.identityPositiveUrl == '' || row.identityPositiveUrl == null || row.identityPositiveUrl == 'null') {
                                return "";
                            } else {
                                return "查看附件";
                            }
                        }
                    },
                    {label:'司机身份证反面附件',type: "imgLink",prop:'identityBackUrl',align:'center',width:'160',
                        formatter: (row) => {
                            if (row.identityBackUrl == '' || row.identityBackUrl == null || row.identityBackUrl == 'null') {
                                return "";
                            } else {
                                return "查看附件";
                            }
                        }
                    },
                    {label:'合作合同附件',type: "imgLink",prop:'contractUrl',width:'160',align:'center',
                        formatter: (row) => {
                            if (row.contractUrl == 'null' || row.contractUrl == '' || row.contractUrl == null) {
                                return "";
                            } else {
                                return "查看附件";
                            }
                        }
                    },
                    {label:'备注',prop:'remark',width:'160',align:'center'},
                    {label:'创建人',prop:'creator',width:'100',align:'center'},
                    {label:'创建时间',prop:'createTime',width:'160',align:'center',
                        formatter: (row, column) => {
                            if(row.createTime == null){
                                return ''
                            }else {
                                return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    },
                    {label:'更新人',prop:'modifier',width:'100',align:'center'},
                    {label:'创建时间',prop:'createTime',width:'160',align:'center',
                        formatter: (row, column) => {
                            if(row.modifyTime == null){
                                return ''
                            }else {
                                return this.$moment(row.modifyTime).format("YYYY-MM-DD HH:mm:ss")
                            }
                        }
                    }
                ],
                pagination:{ //分页数据
                    pageSize:50,
                    pageNum:1,
                    total:0
                },
                merge:[],
                deliveryBillSearchFlag:true,
                deliverFileModifyFlag:true
            }
        },
        computed:{
            getUploadUrl(){
                let filePath = `${this.$filePath}`;
                return filePath
            }
        },
        components:{ //引入组件后注册组件
            cesTable,
            Drawer
        },
        mounted(){
            this.getTableAndForm();
            let parentId = '2_3_4';
            let currentPageButtons = arrayQuery.text(parentId);
            let _this = this;
            _this.currentPageButtons = currentPageButtons;
            //查询按钮权限
            let deliveryBillSearch = currentPageButtons.find(item => {
                return item.menuId == '2_3_4_1';
            });
            if(deliveryBillSearch == undefined){
                _this.deliveryBillSearchFlag = true;
            }else {
                _this.deliveryBillSearchFlag = true;
            }
            //修改按钮权限
            let deliverFileModify = currentPageButtons.find(item => {
                return item.menuId == '2_3_4_1';
            });
            if(deliverFileModify == undefined){
                _this.deliverFileModifyFlag = false;
            }else {
                _this.deliverFileModifyFlag = true;
            }
        },
        methods:{
            //---------------------------------------司机驾驶副本副本--------------------------------
            handleUpSuccess(response, file, fileList) {
                this.dialogImageUrl = `${this.$imageiPPath}`+response.data;
                this.driverFileForm.driverLicenseUrl = response.data;
                this.hideUp = fileList.length == 1;
                this.$message.success(response.msg);
                this.imgflag = false;
                this.imgflagmodel = true;
                this.imgflagdel = true;
                this.imgurl_s = `${this.$imageiPPath}`+response.data;
                this.srcList.push(`${this.$imageiPPath}`+response.data);
            },
            handleUpError(err, file, fileList) {},
            // 删除图片
            handleUpRemove(file, fileList) {
                this.hideUp = false;
                this.driverFileForm.driverLicenseUrl = '';
                this.noneBtnImg = fileList.length >= this.limitCountImg;
            },
            // 预览图片
            handleUpPreview(file) {
                this.dialogVisible = true;
            },
            // 上传图片
            handleUpUpload(file, fileList) {
                this.hideUp = true;
                this.noneBtnImg = fileList.length >= this.limitCountImg;
            },
            delimg(){
                this.imgflag = true;
                this.imgflagdel = false;
                this.imgflagmodel = false;
                this.driverFileForm.driverLicenseUrl = '';
            },
            //---------------------------------------司机行驶证副本--------------------------------
            handleUpSuccess1(response, file, fileList) {
                this.dialogImageUrl1 = `${this.$imageiPPath}`+response.data;
                this.driverFileForm.vehicleLicenseUrl = response.data;
                this.hideUp1 = fileList.length == 1;
                this.$message.success(response.msg);
                this.imgflag1 = false;
                this.imgflagmodel1 = true;
                this.imgflagdel1 = true;
                this.imgurl_s_vehicleLicenseUrl = `${this.$imageiPPath}`+response.data;
                this.srcList1.push(`${this.$imageiPPath}`+response.data);
            },
            handleUpError1(err, file, fileList) {},
            // 删除图片
            handleUpRemove1(file, fileList) {
                this.hideUp1 = false;
                this.driverFileForm.vehicleLicenseUrl = '';
                this.noneBtnImg1 = fileList.length >= this.limitCountImg1;
            },
            // 预览图片
            handleUpPreview1(file) {
                this.dialogVisible1 = true;
            },
            // 上传图片
            handleUpUpload1(file, fileList) {
                this.hideUp1 = true;
                this.noneBtnImg1 = fileList.length >= this.limitCountImg1;
            },
            delimg1(){
                this.imgflag1 = true;
                this.imgflagdel1 = false;
                this.imgflagmodel1 = false;
                this.driverFileForm.vehicleLicenseUrl = '';
            },
            //---------------------------------------司机身份证正面--------------------------------
            handleUpSuccess2(response, file, fileList) {
                this.dialogImageUrl2 = `${this.$imageiPPath}`+response.data;
                this.driverFileForm.identityPositiveUrl = response.data;
                this.hideUp2 = fileList.length == 1;
                this.$message.success(response.msg);
                this.imgflag2 = false;
                this.imgflagmodel2 = true;
                this.imgflagdel2 = true;
                this.imgurl_s_identityPositiveUrl = `${this.$imageiPPath}`+response.data;
                this.srcList2.push(`${this.$imageiPPath}`+response.data);
            },
            handleUpError2(err, file, fileList) {},
            // 删除图片
            handleUpRemove2(file, fileList) {
                this.hideUp2 = false;
                this.driverFileForm.identityPositiveUrl = '';
                this.noneBtnImg2 = fileList.length >= this.limitCountImg2;
            },
            // 预览图片
            handleUpPreview2(file) {
                this.dialogVisible2 = true;
            },
            // 上传图片
            handleUpUpload2(file, fileList) {
                this.hideUp2 = true;
                this.noneBtnImg2 = fileList.length >= this.limitCountImg2;
            },
            delimg2(){
                this.imgflag2 = true;
                this.imgflagdel2 = false;
                this.imgflagmodel2 = false;
                this.driverFileForm.identityPositiveUrl = '';
            },
            //---------------------------------------司机身份证正面--------------------------------
            handleUpSuccess3(response, file, fileList) {
                this.dialogImageUrl3 = `${this.$imageiPPath}`+response.data;
                this.driverFileForm.identityBackUrl = response.data;
                this.hideUp3 = fileList.length == 1;
                this.$message.success(response.msg);
                this.imgflag3 = false;
                this.imgflagmodel3 = true;
                this.imgflagdel3 = true;
                this.imgurl_s_identityBackUrl = `${this.$imageiPPath}`+response.data;
                this.srcList3.push(`${this.$imageiPPath}`+response.data);
            },
            handleUpError3(err, file, fileList) {},
            // 删除图片
            handleUpRemove3(file, fileList) {
                this.hideUp3 = false;
                this.driverFileForm.identityBackUrl = '';
                this.noneBtnImg3 = fileList.length >= this.limitCountImg3;
            },
            // 预览图片
            handleUpPreview3(file) {
                this.dialogVisible3 = true;
            },
            // 上传图片
            handleUpUpload3(file, fileList) {
                this.hideUp3 = true;
                this.noneBtnImg3 = fileList.length >= this.limitCountImg3;
            },
            delimg3(){
                this.imgflag3 = true;
                this.imgflagdel3 = false;
                this.imgflagmodel3 = false;
                this.driverFileForm.identityBackUrl = '';
            },
            //---------------------------------------司机合作合同身附件--------------------------------
            handleUpSuccess4(response, file, fileList) {
                this.dialogImageUrl4 = `${this.$imageiPPath}`+response.data;
                this.driverFileForm.contractUrl = response.data;
                this.hideUp4 = fileList.length == 1;
                this.$message.success(response.msg);
                this.imgflag4 = false;
                this.imgflagmodel4 = true;
                this.imgflagdel4 = true;
                this.imgurl_s_DcontractUrl = `${this.$imageiPPath}`+response.data;
                this.srcList4.push(`${this.$imageiPPath}`+response.data);
            },
            handleUpError4(err, file, fileList) {},
            // 删除图片
            handleUpRemove4(file, fileList) {
                this.hideUp4 = false;
                this.driverFileForm.contractUrl = '';
                this.noneBtnImg4 = fileList.length >= this.limitCountImg4;
            },
            // 预览图片
            handleUpPreview4(file) {
                this.dialogVisible4 = true;
            },
            // 上传图片
            handleUpUpload4(file, fileList) {
                this.hideUp4 = true;
                this.noneBtnImg4 = fileList.length >= this.limitCountImg4;
            },
            delimg4(){
                this.imgflag4 = true;
                this.imgflagdel4 = false;
                this.imgflagmodel4 = false;
                this.driverFileForm.contractUrl = '';
            },
            save_driverFile(){
                //运单新增
                const data = new URLSearchParams();
                data.append('userId', this.driverFileForm.userId);
                data.append('username', this.driverFileForm.username);
                data.append('name', this.driverFileForm.name);
                data.append('verify', this.driverFileForm.verify);
                data.append('status', this.driverFileForm.status);
                data.append('driverLicense', this.driverFileForm.driverLicense);
                data.append('vehicleLicense', this.driverFileForm.vehicleLicense);
                data.append('identityCard', this.driverFileForm.identityCard);
                data.append('contractNo', this.driverFileForm.contractNo);
                data.append('driverLicenseUrl', this.driverFileForm.driverLicenseUrl);
                data.append('vehicleLicenseUrl', this.driverFileForm.vehicleLicenseUrl);
                data.append('identityPositiveUrl', this.driverFileForm.identityPositiveUrl);
                data.append('identityBackUrl', this.driverFileForm.identityBackUrl);
                data.append('contractUrl', this.driverFileForm.contractUrl);
                data.append('remark', this.driverFileForm.remark);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/driver/updateDriverFiles`,
                    data:data
                }).then(res=> {
                    if(res.data.success == true){
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        });
                        this.handleClose_driverFile();
                        this.deliverFileSearch();
                    }else {
                        this.$alert(res.data.msg, '提示', {
                            confirmButtonText: '确定',
                        });
                        this.handleClose_driverFile();
                    }
                });
            },
            clearImgstarus(){
                this.imgflagmodel = false;
                this.imgflagdel = false;
                this.imgflag = true;
                this.srcList.length = 0;

                this.imgflagmodel1 = false;
                this.imgflagdel1 = false;
                this.imgflag1 = true;
                this.srcList1.length = 0;

                this.imgflagmodel2 = false;
                this.imgflagdel2 = false;
                this.imgflag2 = true;
                this.srcList2.length = 0;

                this.imgflagmodel3 = false;
                this.imgflagdel3 = false;
                this.imgflag3 = true;
                this.srcList3.length = 0;

                this.imgflagmodel4 = false;
                this.imgflagdel4 = false;
                this.imgflag4 = true;
                this.srcList4.length = 0;

            },
            handleClose_driverFile(){
                this.dialog_driverFile = false;
                this.$refs.driverFileForm.resetFields();
                this.clearImgstarus();
            },
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.deliverFileSearch()
            },
            handleQuery(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                //快捷查询
                this.loading = true;
                const data = new URLSearchParams();
                data.append('name', this.AccurateAndUnique_name);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/driver/queryDriverFilesPage`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.data.items;
                    this.pagination.total = res.data.data.total;
                    this.loading = false;
                });
            },
            //获取表格和表单数据
            async getTableAndForm(){
                this.deliverFileSearch();
            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection
            },
            handleImgClick(imgurl){
                this.dialogImageUrl_Cli = `${this.$imageiPPath}`+imgurl.imgurl;
                this.dialogImageUrl_Cli_srcList.push(`${this.$imageiPPath}`+imgurl.imgurl);
                this.dialogVisibleImgUrl = true;
            },
            handleCloseImgDialog(){
                this.dialogImageUrl_Cli = '';
                this.dialogImageUrl_Cli_srcList.length = 0;
                this.dialogVisibleImgUrl = false;
            },
            //订单查询
            deliverFileSearch(){
                this.loading = true;
                const data = new URLSearchParams();
                data.append('userId', this.dataForms.userId);
                data.append('username', this.dataForms.username);
                data.append('name', this.dataForms.name);
                data.append('verify', this.dataForms.verify);
                data.append('status', this.dataForms.status);
                data.append('driverLicense',this.dataForms.driverLicense);
                data.append('vehicleLicense', this.dataForms.vehicleLicense);
                data.append('identityCard', this.dataForms.identityCard);
                data.append('contractNo', this.dataForms.contractNo);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/driver/queryDriverFilesPage`,
                    data:data
                }).then(res=> {
                    if(res.data.success == true){
                        this.tableData = res.data.data.items;
                        this.pagination.total = res.data.data.total;
                        this.loading = false;
                    }else {
                        this.$alert(res.data.msg, '提示', {
                            confirmButtonText: '确定',
                        });
                        this.loading = false;
                    }
                });
            },
            deliverFileSearch_Multiple(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.deliverFileSearch();
            },
            //修改
            deliverFileModify(){
                this.selectionLineChangeHandle();
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '修改操作必须选中一条数据'
                    });
                }else if(this.merge.length > 1){
                    this.$message({
                        type: 'info',
                        message: '修改操作只能选择单条数据进行操作'
                    });
                }else{
                    this.dialog_driverFile = true;
                    axios.get(`${this.$apiPath}/api/urban/driver/queryDriverFilesById?userId=`+ this.merge[0].userId).then(res=>{
                        if(res.data.data.status == true){
                            res.data.data.status = '1';
                        }else if(res.data.data.status == false){
                            res.data.data.status = '0';
                        }
                        this.driverFileForm = res.data.data;

                        if(res.data.data.driverLicenseUrl == null || res.data.data.driverLicenseUrl == '' || res.data.data.driverLicenseUrl == 'null'){
                            this.imgflag = true;
                            this.imgflagmodel = false;
                            this.imgflagdel = false;

                        }else{
                            this.imgflag = false;
                            this.imgflagmodel = true;
                            this.imgflagdel = true;
                            this.imgurl_s = `${this.$imageiPPath}`+res.data.data.driverLicenseUrl;
                            this.srcList.push(`${this.$imageiPPath}`+res.data.data.driverLicenseUrl);
                        }

                        if(res.data.data.vehicleLicenseUrl == null || res.data.data.vehicleLicenseUrl == '' || res.data.data.vehicleLicenseUrl == 'null'){
                            this.imgflag1 = true;
                            this.imgflagmodel1 = false;
                            this.imgflagdel1 = false;

                        }else{
                            this.imgflag1 = false;
                            this.imgflagmodel1 = true;
                            this.imgflagdel1 = true;
                            this.imgurl_s_vehicleLicenseUrl = `${this.$imageiPPath}`+res.data.data.vehicleLicenseUrl;
                            this.srcList1.push(`${this.$imageiPPath}`+res.data.data.vehicleLicenseUrl);
                        }

                        if(res.data.data.identityPositiveUrl == null || res.data.data.identityPositiveUrl == '' || res.data.data.identityPositiveUrl == 'null'){
                            this.imgflag2 = true;
                            this.imgflagmodel2 = false;
                            this.imgflagdel2 = false;

                        }else {
                            this.imgflag2 = false;
                            this.imgflagmodel2 = true;
                            this.imgflagdel2 = true;
                            this.imgurl_s_identityPositiveUrl = `${this.$imageiPPath}`+res.data.data.identityPositiveUrl;
                            this.srcList2.push(`${this.$imageiPPath}`+res.data.data.identityPositiveUrl);
                        }
                        if(res.data.data.identityBackUrl == null || res.data.data.identityBackUrl == '' || res.data.data.identityBackUrl == 'null'){
                            this.imgflag3 = true;
                            this.imgflagmodel3 = false;
                            this.imgflagdel3 = false;
                        }else {
                            this.imgflag3 = false;
                            this.imgflagmodel3 = true;
                            this.imgflagdel3 = true;
                            this.imgurl_s_identityBackUrl = `${this.$imageiPPath}`+res.data.data.identityBackUrl;
                            this.srcList3.push(`${this.$imageiPPath}`+res.data.data.identityBackUrl);
                        }

                        if(res.data.data.contractUrl == null || res.data.data.contractUrl == '' || res.data.data.contractUrl == 'null'){
                            this.imgflag4 = true;
                            this.imgflagmodel4 = false;
                            this.imgflagdel4 = false;
                        }else {
                            this.imgflag4 = false;
                            this.imgflagmodel4 = true;
                            this.imgflagdel4 = true;
                            this.imgurl_s_DcontractUrl = `${this.$imageiPPath}`+res.data.data.contractUrl;
                            this.srcList4.push(`${this.$imageiPPath}`+res.data.data.contractUrl);
                        }

                    })
                }
            },
            handleClose(done) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.timer = setTimeout(() => {
                    // 动画关闭需要一定的时间
                    setTimeout(() => {
                        this.loading = false;
                    }, 400);
                    this.deliverFileSearch_Multiple()
                    // 动画关闭需要一定的时间
                    this.dialog = false;
                }, 2000);
            },
            cancelForm() {
                this.loading = false;
                this.dialog = false;
                clearTimeout(this.timer);
            }
        }
    }
</script>
<style>
    .tableMain .ces-table-page{
        height: 100%;
    }
    .tableMain .ces-table{
        height: 100%;
    }
    .tableMain .el-table th{
        padding: 0;
        height: 35px;
    }
    .tableMain .el-table td{
        padding: 0;
        height: 35px;
    }
</style>
<style scoped>
    .boxbutton{
        width: 100%;
        height: 40px;
    }
    .tableMain{
        width: 100%;
        height: 82%;
    }
</style>
